<template>
  <div class="space-y-10">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div v-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <article v-if="hasButton">
        <h3 class="subheader">
          Quick Links
        </h3>
        <br />
        <section
          class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
        >
          <asom-button
            v-if="buttonDisplay.showFullHandover"
            variant="primary"
            outline
            rounded="none"
            @click="
              $router.push({
                name: 'Full Shift Handover',
              })
            "
            icon="calendar-two"
            text="Full Handover"
          >
          </asom-button>
          <asom-button
            v-if="buttonDisplay.showFullTakeover && !!lastIncompletedHandover"
            variant="primary"
            outline
            rounded="none"
            @click="
              $router.push({
                name: 'Shift Handover Takeover',
                params: lastIncompletedHandover,
              })
            "
            icon="calendar-two"
            text="Full Takeover"
          >
          </asom-button>
          <asom-button
            v-if="buttonDisplay.showSoftHandover"
            variant="primary"
            outline
            rounded="none"
            @click="
              $router.push({
                name: 'Soft Shift Handover',
              })
            "
            icon="tag"
            text="Soft Handover"
          >
          </asom-button>
          <asom-button
            v-if="buttonDisplay.showSoftTakeover"
            variant="primary"
            outline
            rounded="none"
            @click="
              $router.push({
                name: 'Soft Shift Takeover',
                params: lastIncompletedSoftHandover
              })
            "
            icon="tag"
            text="Soft Takeover"
          >
          </asom-button>
        </section>
      </article>
      <asom-alert
        variant="warning"
        v-else-if="isAllApiOnline"
        title="No action available"
      >
        <p class="text-sm pt-1">
          Please check if this station has any incomplete shift handover, and 
          contact the officer in charge to complete the takeover procedure.
        </p>
      </asom-alert>

      <asom-card title="Handover screening">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-6 gap-4">
          <asom-form-field label="Date" required>
            <asom-input-date-range
              v-model="filters.dateRange"
              datepicker-placement="bottom"
            />
          </asom-form-field>
        </div>
        <template #footer>
          <asom-button
            text="Reset Filters"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button text="Apply" @click="getPageData(true)" />
        </template>
      </asom-card>

      <div class="pt-1">
        <asom-client-table
          :columns="[
            'shiftHandoverNo',
            'type',
            'status',
            'stationName',
            'handOverUserName',
            'handOverRosterDWS',
            'takeOverUserName',
            'takeOverRosterDWS',
          ]"
          :sortableColumns="[
            'shiftHandoverNo',
            'type',
            'status',
            'stationName',
            'handOverUserName',
            'handOverRosterDWS',
            'takeOverUserName',
            'takeOverRosterDWS',
          ]"
          :data="tableData"
        >
          <template v-slot:header_statioName>Station</template>
          <template v-slot:header_handOverUserName>Handover By</template>
          <template v-slot:header_handOverRosterDWS>Handover Roster</template>
          <template v-slot:header_takeOverUserName>Takeover By</template>
          <template v-slot:header_takeOverRosterDWS>Takeover Roster</template>
          <template v-slot:takeOverRosterDWS="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:takeOverUserName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:shiftHandoverNo="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ scopedSlots.data }}
            </button>
          </template>
          <template v-slot:startDate="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
          <template v-slot:endDate="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
        </asom-client-table>
      </div>
    </template>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { getShiftHandoverReportList } from "../../services/shiftHandover.service";
import { GET_SHIFT_HANDOVER_TYPES } from "../../constants/apis";
import { getAsync } from "../../services/serviceHandlers";
import { HandoverTypes } from "../../constants/APIEnums/shiftHandoverEnums";
import { toMoment, displayUtcDate} from "@/helpers/dateTimeHelpers";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(1, "month").toDate(),
          end: moment().toDate(),
        },
      },
      isLoading: false,
      tableData: [],
      error: null,
      buttonDisplay: {
        showFullHandover: false,
        showFullTakeover: false,
        showSoftHandover: false,
        showSoftTakeover: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      lineId: "auth/userLineId",
      stationId: "currentRoster/currentStationId",
      selectedStationId: "selectedStation/id",
      // stationId: "auth/userStationId",
      isAllApiOnline: "apiStatus/isAllApiOnline",
    }),
    lastIncompletedHandover() {
      return this.tableData.find(
        (shiftHandover) =>
          !shiftHandover.isCompleted &&
          shiftHandover.takeOverUserId == this.userId
      );
    },
    lastIncompletedSoftHandover() {
      return this.tableData.find(
        (shiftHandover) =>
          !shiftHandover.isCompleted &&
          shiftHandover.typeEnum == HandoverTypes.SOFT_HANDOVER.VALUE
      );
    },
    hasButton() {
      return (
        this.buttonDisplay.showFullHandover ||
        this.buttonDisplay.showFullTakeover ||
        this.buttonDisplay.showSoftHandover ||
        this.buttonDisplay.showSoftTakeover
      );
    },
  },
  mounted() {
    const searchVal = getStorage("shift-handover");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.getButtonDisplay();
      this.getPageData();
    })
  },
  methods: {
    displayUtcDate,
    resetFilters() {
      this.filters.dateRange = {
        start: moment().subtract(1, "month").toDate(),
        end: moment().toDate(),
      }
    },
    async getButtonDisplay() {
      const resp = await getAsync({
        url: GET_SHIFT_HANDOVER_TYPES,
        params: {
          stationId: this.selectedStationId,
        },
      });
      if (resp.success) {
        this.buttonDisplay.showFullHandover = get(
          resp.payload,
          "showFullHandover"
        );
        this.buttonDisplay.showFullTakeover = get(
          resp.payload,
          "showFullTakeover"
        );
        this.buttonDisplay.showSoftHandover = get(
          resp.payload,
          "showSoftHandover"
        );
        this.buttonDisplay.showSoftTakeover = get(
          resp.payload,
          "showSoftTakeover"
        );
      }
    },
    async getPageData(status) {
      if(status) setStorage("shift-handover", this.filters);
      this.isLoading = true;
      const resp = await getShiftHandoverReportList({
        stationId: this.selectedStationId,
        lineId: this.lineId,
        SearchDateFrom : this.parseDateTimeUTC(this.filters.dateRange.start, true),
        SearchDateTo:this.parseDateTimeUTC(this.filters.dateRange.end, false),
      });
      if (resp.success) {
        this.total = resp.payload.total;
        this.tableData = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(shiftHandoverDetails) {
      const isRequiredSignOff =
        !shiftHandoverDetails.isCompleted &&
        shiftHandoverDetails.takeOverUserId === this.userId;

      if (isRequiredSignOff)
        this.$router.push({
          name: "Shift Handover Takeover",
          params: shiftHandoverDetails,
        });
      else
        this.$router.push({
          name: "Shift Handover Details",
          params: shiftHandoverDetails,
        });
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf('day') : d.endOf('day');
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss")
      }
      return ""
    },
  },
};
</script>
